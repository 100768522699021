// Swiper
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

Swiper.use([Navigation, Pagination, Autoplay]);

new Swiper('.hero-slider', {
  // Optional parameters
  autoHeight: true,
  freeMode: true,
  direction: 'vertical',
  loop: true,

  autoplay: {
    delay: 5000,
  },

  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

new Swiper('.gallery-slider', {
  // Optional parameters
  slidesPerView: 'auto',
  spaceBetween: 30,
  centeredSlides: true,
  centeredSlidesBounds: true,
  freeMode: true,
  passiveListeners: true,

  autoplay: {
    delay: 5000,
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

new Swiper('.archive-foundation-events', {
  // Optional parameters
  freeMode: true,

  // Navigation arrows
  navigation: {
    nextEl: '#next-1',
    prevEl: '#prev-1',
  },

  breakpoints: {
    320: {
      slidesPerView: 'auto',
      spaceBetween: 16,
    },
    782: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },
});

new Swiper('.archive-community-events', {
  // Optional parameters
  freeMode: true,

  // Navigation arrows
  navigation: {
    nextEl: '#next-2',
    prevEl: '#prev-2',
  },

  breakpoints: {
    320: {
      slidesPerView: 'auto',
      spaceBetween: 16,
    },
    782: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
  },
});

// Function that enables/disables events listing swiper depending on browser size
function eventsListingSwiper() {
  const hasEventsListing = document.getElementsByClassName('events-listing');
  if (hasEventsListing.length > 0) {
    let ww = $(window).width();
    let events_list_swiper = undefined;

    if (ww >= 389 && ww <= 782) {
      events_list_swiper = new Swiper ('.events-list', { // Optional parameters
        slidesPerView: 'auto',
        spaceBetween: 16,
      });
    }
  
    $(window).resize(function(){
      ww = $(window).width();
  
      if (ww >= 389 && ww <= 782) {
        if (events_list_swiper == undefined) {
          events_list_swiper = new Swiper ('.events-list', { // Optional parameters
            slidesPerView: 'auto',
            spaceBetween: 16,
          });
        }
      }
      else if ((ww < 389 || ww > 782) && events_list_swiper != undefined) {
        events_list_swiper[0].destroy();
        events_list_swiper[1].destroy();
        events_list_swiper = undefined;
      }
    });
  }
}

// Function that enables/disables swiper depending on browser size
function latestEventsSwiper() {
  const hasLatestEvents = document.getElementsByClassName('latest-events');
  if (hasLatestEvents.length > 0) {
    let ww = $(window).width();
    let latest_events_swiper = undefined;

    if (ww >= 389 && ww <= 782) {
      latest_events_swiper = new Swiper ('.latest-events-swiper', { // Optional parameters
        slidesPerView: 'auto',
        spaceBetween: 16,
      });
    }
  
    $(window).resize(function(){
      ww = $(window).width();
  
      if (ww >= 389 && ww <= 782) {
        if (latest_events_swiper == undefined) {
          latest_events_swiper = new Swiper ('.latest-events-swiper', { // Optional parameters
            slidesPerView: 'auto',
            spaceBetween: 16,
          });
        }
      }
      else if ((ww < 389 || ww > 782) && latest_events_swiper != undefined) {
        latest_events_swiper.destroy();
        latest_events_swiper = undefined;
      }
    });
  }
}

$(window).load(function() {
  eventsListingSwiper();
  latestEventsSwiper();
});
