import InitMobileMenuJS from '../components/InitMobileMenuJS';
// import loadMorePosts from '../components/loadMorePosts';
import handleAnimationTimelines from '../components/animateTimeline';
import OpenSearchbar from '../components/openSearchbar';


export default {
  init() {
    // JavaScript to be fired on all pages
    InitMobileMenuJS()
    // loadMorePosts()
    handleAnimationTimelines()
    OpenSearchbar()
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
