(function () {
  'use strict';

  const searchButton = document.querySelector('.search-button');
  const searchBox = document.querySelector('.search-box');

  searchButton.addEventListener('click', () => {
    searchBox.classList.toggle('close');
  });

})();
