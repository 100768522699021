const InitMobileMenuJS = () => {
  // Menu toggling
  const mobileToggle = document.querySelector('.mobile-navigation-toggle');
  const mobileNav = document.querySelector('.mobile-navigation-wrapper');

  mobileToggle.addEventListener('click', () => {
    mobileToggle.classList.toggle('active');
    mobileNav.classList.toggle('open');
  });

  // Sub menu toggling
  const subnavToggle = document.querySelectorAll('.sub-nav-toggle');

  subnavToggle.forEach((item)=>{
    item.addEventListener('click', () => {
      item.classList.toggle('active');
      item.nextElementSibling.classList.toggle('open')
    })
  })

}

export default InitMobileMenuJS
